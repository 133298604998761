<template>
  <div id="app">
    <PragmaticIframe></PragmaticIframe>
  </div>
</template>

<script>

import PragmaticIframe from '@/components/PragmaticIframe';

export default {
  name: 'App',
  components: {
    PragmaticIframe,
  },
};
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
</style>
