import Validation from './Validation';

class QueryParams {
  constructor(queryParams) {
    const requiredFields = ['game', 'language', 'tenantUuid'];
    Validation.checkRequiredFieldsInObject(requiredFields, queryParams);
    this.queryParams = queryParams;
  }

  get currency() {
    return this.queryParams.currency;
  }

  get country() {
    return this.queryParams.country;
  }

  get game() {
    return this.queryParams.game;
  }

  get isMobile() {
    return this.queryParams.isMobile || 'false';
  }

  get language() {
    return this.queryParams.language;
  }

  get sevenToken() {
    return this.queryParams.sevenToken || '';
  }

  get tenantUuid() {
    return this.queryParams.tenantUuid;
  }

  get referrerUrl() {
    return this.queryParams.referrerUrl;
  }

  isDemo() {
    if (!this.queryParams.sevenToken) {
      return true;
    }
    if (Object.prototype.hasOwnProperty.call(this.queryParams, 'demo')) {
      return this.queryParams.demo === 'true';
    }
    return false;
  }
}

export default QueryParams;
