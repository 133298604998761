<template>
  <div id="frame-container" class="fill-parent">
    <iframe :src="pragmaticSource" class="fill-parent no-border"></iframe>
  </div>
</template>

<script>
import isMobile from 'ismobilejs';
import { QueryParams, queryStringParser } from '@/utility';
import { auth, configApi, gameUrl } from '@/api';
import errorTracker from '@/utility/errorTracker';

export default {
  name: 'pragmaticIframe',
  data() {
    return {
      pragmaticSource: '',
      config: '',
      queryParams: {},
      token: '',
    };
  },
  methods: {
    processPragmaticMessage(message) {
      if (message.data && message.data.name === 'gameQuit') {
        this.processGameQuit();
      }
    },
    processGameQuit() {
      if (typeof Android !== 'undefined') {
        // eslint-disable-next-line no-undef
        Android.exitGame();
      }
      window.parent.postMessage('closeGame', '*');
    },
    getGameUrl(demo) {
      return gameUrl.getGameUrl({
        tenantUuid: this.queryParams.tenantUuid,
        country: this.queryParams.country,
        currency: this.queryParams.currency,
        language: this.queryParams.language,
        playMode: demo ? 'DEMO' : 'REAL',
        symbol: this.queryParams.game,
        token: demo ? null : this.token,
        platform: isMobile().any ? 'MOBILE' : 'WEB',
      });
    },
    async loadDemoGame() {
      try {
        const gameData = await this.getGameUrl(true);
        this.pragmaticSource = gameData.url;
      } catch (e) {
        if (window.parent) {
          window.parent.postMessage({ error: true, message: 'Something went wrong while loading game url.' }, '*');
        } else {
          console.log('[Integrations-PP] - Something went wrong while loading game url.');
        }
      }
    },
    async loadRealGame() {
      try {
        this.token = await auth.login({
          jwt: this.queryParams.sevenToken,
          tenantUuid: this.queryParams.tenantUuid,
        });
      } catch (e) {
        const responseData = (e.response && e.response.data) || {};
        const defaultMessage = 'Something went wrong with login';

        if (responseData.error && responseData.error !== 10) {
          return;
        }

        const message = responseData.description || defaultMessage;

        if (window.parent) {
          window.parent.postMessage({ error: true, message }, '*');
        } else {
          console.log(`[Integrations-PP] - ${defaultMessage}`);
        }

        return;
      }

      try {
        const gameData = await this.getGameUrl();
        this.pragmaticSource = gameData.url;
      } catch (e) {
        if (window.parent) {
          window.parent.postMessage({ error: true, message: 'Something went wrong while loading game url.' }, '*');
        } else {
          console.log('[Integrations-PP] - Something went wrong while loading game url.');
        }
      }
    },
  },
  async mounted() {
    this.queryParams = new QueryParams(queryStringParser.getQueryParams());
    errorTracker.maybeTrackTenantEnvMismatch(this.queryParams.tenantUuid);
    this.config = await configApi.getConfig(this.queryParams.tenantUuid);
    window.addEventListener('message', this.processPragmaticMessage, false);
    if (this.queryParams.isDemo()) {
      await this.loadDemoGame();
    } else {
      await this.loadRealGame();
    }
  },
};
</script>

<style>
.fill-parent {
    height: 100%;
    width: 100%;
}
.no-border {
  border-width: 0;
}
</style>
