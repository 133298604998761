import * as Sentry from '@sentry/vue';
import Vue from 'vue';

const errorTracker = {
  enabled: process.env.VUE_APP_ERROR_TRACKER_ACTIVE === 'true',
  maybeTrackTenantEnvMismatch(tenantUuid) {
    const mismatchCompanies = process.env.VUE_APP_ERROR_TRACKER_CONFIG_MISMATCH_TENANTS.split(',');

    if (!mismatchCompanies.includes(tenantUuid)) return;

    this.maybeTrackMessage(`Company env mismatch ${tenantUuid}`);
  },
  maybeTrackMessage(message) {
    if (!this.enabled) return;

    Sentry.captureMessage(message);
  },
  maybeInitErrorTracker() {
    if (!this.enabled) return;

    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.001,
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [
        /Java bridge method invocation error/,
        /Request failed with status code 400/,
        /Android.receiveMessage is not a function/,
        /Network Error/,
      ],
    });
  },
};

export default errorTracker;
