import integrationsHttp from './http';

export default {
  async login({ jwt, tenantUuid }) {
    const config = {
      headers: {
        token: jwt,
      },
    };

    const response = await integrationsHttp.post(`/${tenantUuid}/login`, {}, config);
    return response.data;
  },
};
