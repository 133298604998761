import integrationsHttp from './http';

export default {
  async getConfig(tenantUuid) {
    const response = await integrationsHttp.get(`/${tenantUuid}/config`);
    const { data } = response;

    return {
      gameServerDomain: data.gameServer,
      secureLogin: data.secureLogin,
      lobbyUrl: data.lobbyUrl,
    };
  },
};
