import integrationsHttp from './http';

export default {
  async getGameUrl({
    tenantUuid,
    country,
    currency,
    language,
    playMode,
    symbol,
    token,
    platform,
  }) {
    const payload = {
      country,
      currency,
      language,
      playMode,
      symbol,
      token,
      platform,
    };

    const response = await integrationsHttp.post(`/${tenantUuid}/game-url`, payload);
    const { data } = response;

    return {
      url: data.url,
    };
  },
};
