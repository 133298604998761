class Validation {
  /**
     *
     * @param {Array} requiredFields
     * @param {Object} object
     */
  static checkRequiredFieldsInObject(requiredFields, object) {
    requiredFields.forEach((field) => {
      Validation.checkRequiredFieldInObject(field, object);
    });
  }

  /**
   *
   * @param {Array} requiredField
   * @param {Object} object
   */
  static checkRequiredFieldInObject(requiredField, object) {
    if (!Object.prototype.hasOwnProperty.call(object, requiredField)) {
      throw new Error(`${requiredField} doesn't exist in object`);
    }
    if (object[requiredField] === '') {
      throw new Error(`${requiredField} has an empty value`);
    }
  }
}

export default Validation;
